import { gql } from '@apollo/client';

export const CREATE_GAS_STATION_REPORT_NEW = gql`
  mutation CreateGasStationReportNew(
    $fullAddress: String!
    $carName: String!
    $city: String!
    $latitude: Float!
    $longitude: Float!
    $plate: String!
    $street: String!
    $streetNumber: String!
    $zipCode: String!
    $images: [Int!]!
    $bill: Upload!
    $description: String
    $parkingZoneId: ID
    $price: Float!
    $inStore: Boolean!
    $notes: String
  ) {
    createGasStationReportNew(
      input: {
        address_full: $fullAddress
        car_name: $carName
        city: $city
        latitude: $latitude
        longitude: $longitude
        plate: $plate
        street: $street
        street_number: $streetNumber
        zip_code: $zipCode
        description: $description
        price: $price
        parkingZone: { connect: $parkingZoneId }
        in_store: $inStore
        notes: $notes
      }
      images: $images
      bill: $bill
    ) {
      id
      hash
    }
  }
`;
